import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';

const RepublicDay2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_archive_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/RepublicDay/1.webp`;
const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/RepublicDay/2.webp`;
const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/RepublicDay/3.webp`;
const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/RepublicDay/4.webp`;
const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/RepublicDay/5.webp`;
const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/RepublicDay/6.webp`;
const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/RepublicDay/7.webp`;
const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/RepublicDay/8.webp`;
const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/RepublicDay/9.webp`;
const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/RepublicDay/10.webp`;

    const photos = [
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
        {
          src: p5,
          source: p5,
          rows: 1,
          cols: 1,
      },
      {
          src: p6,
          source: p6,
          rows: 1,
          cols: 1,
      },
      {
          src: p7,
          source: p7,
          rows: 1,
          cols: 1,
      },
      {
          src: p8,
          source: p8,
          rows: 1,
          cols: 1,
      }, 
      {
          src: p9,
          source: p9,
          rows: 1,
          cols: 1,
      },
      {
          src: p10,
          source: p10,
          rows: 1,
          cols: 1,
      }, 
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                REPUBLIC DAY 	
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                               Date: 26 JANUARY 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School Yeshwantpur celebrated the 75th Republic Day with great enthusiasm and patriotic fervor on 26 January 2024. The school organized a grand event to commemorate this significant day in the history of India. The celebration aimed to instil a sense of pride and respect for the nation among the students, fostering a spirit of unity and patriotism.
                                    <br></br>
                                    The Republic Day festivities began with the traditional flag hoisting ceremony in the school premises. The national flag was unfurled by the Principal, in the presence of the staff and the students. The national anthem was sung with utmost reverence, filling the air with the spirit of patriotism.
                                    <br></br>
                                    The flag hoisting ceremony was followed by the march past and then the cultural programme was organized showcasing the diverse cultural heritage of India. Students presented a medley of patriotic songs, skit and dances that depicted the rich history and values of the nation. The performances were not only entertaining but also carried important messages about unity in diversity, freedom and the responsibilities of citizens. Students delivered speeches highlighting the significance of Republic Day, the Constitution of India and the sacrifices made by the freedom fighters. These speeches aimed to educate the audience about the historical context of Republic Day and its importance in shaping the democratic fabric of the country.
                                    <br></br>
                                    The Principal delivered a thought-provoking speech emphasising the importance of upholding democratic values, fostering unity and contributing to the progress of the nation. The students were quizzed on Current Affairs and were advised to be proactive citizens. Teachers and students were commended for their efforts in organising a meaningful celebration that instils a sense of patriotism in the younger generation.
                                    <br></br>
                                    The Republic Day celebration at National Public School was a resounding success, leaving a lasting impact on all the participants. The event not only commemorated the historic day but also served as a platform for students to learn and appreciate the values that make India a diverse and vibrant democracy. The school's commitment to foster a sense of patriotism and civic responsibility was evident throughout the programme, making the celebration a memorable and enriching experience for everyone involved.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “As we raise our flags high, let’s remember the responsibility that comes with the privilege of being an Indian. ”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default RepublicDay2023;